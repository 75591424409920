import React from "react";
import Layout from "../../components/layout.pt";
import SEO from "../../components/seo";

const PrivacyPagePT = () => (
    <Layout>
        <SEO title="Privacidade e Termos do Utilizador" description="A Cottonhat é uma agência web, em Lisboa, que utiliza as tecnologias mais recentes para desenvolver soluções personalizadas" lang="pt" />

        <div className="brook-breadcaump-area bg_color--1 ptb--120 breadcaump-title-bar">
            <div className="container-fluid plr--150 plr_md--50 plr_sm--30">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcaump-inner text-left d-inline-block poss_relative">
                            <h1 className="heading heading-h1 large-font-1">Privacidade e Termos do Utilizador</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="brook-team-area bg_color--5 ptb--150 ptb-sm--60 ptb-md--80 slick-arrow-hover">
            <div className="container">
                <div className="row">
                    <p>
                        This Statement of Privacy applies to the cottonhat.net website (the Website) and governs data collection, processing and usage. By using the Website, you consent to Cottonhat Software Unipessoal LDA data practices described in this statement. If you do not agree to any part
                        of this Statement of Privacy, then you should stop accessing the Website.
                    </p>
                    <h3>Definitions</h3>

                    <ul>
                        <li>
                            “Customer” means a prospective, current or former customer of Cottonhat Software Unipessoal LDA. The term shall also include any individual agent, employee, representative, customer or client of Cottonhat Software Unipessoal LDA where Cottonhat Software Unipessoal LDA has
                            obtained his or her Personal Data from such Customer as part of its business relationship with the Customer.
                        </li>

                        <li>“Data Subject” means an identified or identifiable natural living person about whom Personal Data is being processed.</li>

                        <li>“Employee” means an employee (whether temporary, permanent, part-time or contract) of Cottonhat Software Unipessoal LDA An employee may be a resident of a country within the European Economic area.</li>

                        <li>“Europe” or “European” refers to a country in the European Economic Area.</li>

                        <li>
                            “Personal Data” as defined under the European Union Directive 95/46/EC, Personal Data means data that personally identifies or may be used to personally identify a person, including an individual’s name in combination with country of birth, marital status, emergency
                            contact, salary information, terms of employment, job qualifications (such as educational degrees earned), address, phone number, e-mail address and password. Personal Data does not include data that is de-identified, anonymous, or publicly available. For Switzerland, the
                            term “person” includes a natural person and a legal entity, regardless of the form of the legal entity.
                        </li>

                        <li>“Sensitive Data” means Personal Data that discloses a Data Subject’s medical or health condition, race or ethnicity, political, religious affiliations, sexual orientation, or trade union membership.</li>

                        <li>“Third Party” means any individual or entity that is neither Cottonhat Software Unipessoal LDA nor a Cottonhat Software Unipessoal LDA employee, agent, contractor or representative.</li>
                    </ul>
                    <h3>Collection of your Personal Data</h3>
                    <p>
                        Cottonhat Software Unipessoal LDA may collect and process Personal Data, such as your e-mail address, name, home or work address or telephone number.
                        <br /> If you purchase Cottonhat Software Unipessoal LDA products and services, we may collect and store billing and credit card information.
                        <br /> Cottonhat Software Unipessoal LDA may also collect anonymous demographic information which is not unique to you, such as your postal code, age, gender, preferences, interests and favourites.
                        <br /> Information about your computer hardware and software also may be automatically collected by the Website.
                        <br /> This information can include your IP address, browser type, domain names, access times and referring website addresses.
                        <br />
                        This information is used by Cottonhat Software Unipessoal LDA for the operation of the service, to maintain quality of the service and to provide general statistics regarding use of the Website. Please keep in mind that if you directly disclose Personal Data or Sensitive Data
                        through the Website’s public message boards, this information may be collected and used by others. <br />
                        Cottonhat Software Unipessoal LDA encourages you to review the privacy statements of websites you choose to link to from the Website, so that you can understand how those websites collect, use and share your information.
                        <br /> Cottonhat Software Unipessoal LDA is not responsible for the privacy statements or other content on websites outside of the Cottonhat Software Unipessoal LDA family of websites.
                    </p>

                    <h3>Use of your Personal Data</h3>
                    <p>
                        Cottonhat Software Unipessoal LDA collects and uses your Personal Data to operate the Website and deliver the services. <br />
                        <br />
                        Cottonhat Software Unipessoal LDA will send one welcome email following registration to all registered users. We will also periodically send service updates to registered users.
                        <br /> Cottonhat Software Unipessoal LDA may also use your Personal Data to inform you of other products or services available from Cottonhat Software Unipessoal LDA and its affiliates, where you have consented to be contacted for such purposes. <br />
                        Cottonhat Software Unipessoal LDA may also contact you via surveys to conduct research about your opinion of current services or of potential new services that may be offered, although you do not have to respond to such surveys.
                    </p>

                    <h3>Storage of Personal Data</h3>
                    <p>
                        As part of the services offered to you through the Website, the information you provide to us may be transferred to and stored in countries outside of the European Economic Area (EEA). <br />
                        It may also be processed by Employees operating outside the EEA who work for us or one of our suppliers. By way of example, a transfer of your information may happen if any of our servers are located in a country outside of the EEA or one of our service providers is located
                        in a country outside of the EEA.
                        <br />
                        <br /> If we transfer or store your information outside the EEA in this way, we will take all steps reasonably necessary to ensure that your privacy rights continue to be protected and treated securely as outlined in this Statement. <br />
                        If you use our service while you are outside the EEA, your information may be transferred outside the EEA in order to provide you with these services. <br />
                        By submitting your Personal Data, you agree to this transfer, storing or processing by us. Unfortunately, the transmission of information via the internet is not completely secure. Although we will do our best to protect your Personal Data, we cannot guarantee the security of
                        your data transmitted to our site; any transmission is at your own risk. <br />
                        Once we have received your information, we will use strict procedures and security features to try to prevent unauthorised access. <br />
                    </p>

                    <h3>Disclosures/onward transfers of Personal Data</h3>
                    <p>
                        Cottonhat Software Unipessoal LDA does not sell, rent or lease its customer lists to Third Parties. <br />
                        <br />
                        Cottonhat Software Unipessoal LDA may, from time to time, contact you on behalf of external business partners about a particular offering that may be of interest to you. <br />
                        In those cases, your Personal Data is not transferred to the Third Party without your express consent. In addition, Cottonhat Software Unipessoal LDA may share data with trusted partners to help us perform statistical analysis, send you email or postal mail, provide customer
                        support, or arrange for deliveries.
                        <br /> Cottonhat Software Unipessoal LDA may share your information with Third Parties who perform tasks required to complete a purchase transaction. <br />
                        All such Third Parties are prohibited from using your Personal Data except to provide these services to the Website, and they are required to maintain the confidentiality of your information by agreeing to provide adequate protections for the Personal Data that are no less
                        protective than those set out in this Statement. <br />
                        We may disclose your Personal Data to Third Parties: (a) in the event that we sell or buy any business or assets, in which case we may disclose your Personal Data to the prospective seller or buyer of such business or assets; and (b) if the Website or substantially all of its
                        assets are acquired by a Third Party, in which case, Personal Data held by it about its Customers will be one of the transferred assets.
                    </p>

                    <h3>Sensitive Data</h3>
                    <p>
                        Sensitive Data is any sensitive data relating to you, including information about your political opinion, religious beliefs or any other beliefs of a similar nature. <br />
                        <br />
                        Cottonhat Software Unipessoal LDA will not process Sensitive Data about you without your express consent.
                    </p>

                    <h3>Use of cookies</h3>
                    <p>
                        The Website uses “cookies” to help you personalise your online experience. <br />A cookie is a text file that is placed on your hard drive by a web page server. <br />
                        Cookies cannot be used to run programs or deliver viruses to your computer. <br />
                        Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you. <br />
                        One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the server that you have returned to a specific page. For example, if you personalise the Website pages, or register with the Website site or
                        services, a cookie helps the Website to recall your specific information on subsequent visits. This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on. <br />
                        When you return to the same the Website website, the information you previously provided can be retrieved, so you can easily use the the Website features that you customised. We may use any of the following cookies:
                    </p>

                    <ul>
                        <li>Strictly necessary cookies. These are cookies that are required for the ouleration of the Website. They include, for example, cookies that enable you to log into secure areas of our website, use a shopping cart or make use of e-billing services.</li>
                        <li>
                            Analytical/performance cookies. They allow us to recognise and count the number of visitors and to see how visitors move around the Website when they are using it. This helps us to improve the way our website works, for example, by ensuring that users are finding what
                            they are looking for easily.
                        </li>
                        <li>Functionality cookies. These are used to recognise you when you return to the Website. This enables us to personalise our content for you, greet you by name and remember your preferences (for example, your choice of language or region).</li>
                        <li>
                            Targeting cookies. These cookies record your visit to the Website, the pages you have visited and the links you have followed. We will use this information to make our website and the advertising displayed on it more relevant to your interests. We may also share this
                            information with third parties for this purpose.
                        </li>
                        <p>
                            Please note that third parties (including, for example, advertising networks and providers of external services like web traffic analysis services) may also use cookies, over which we have no control. These cookies are likely to be analytical/performance cookies or
                            targeting cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to access all or parts of our site or to fully experience the
                            interactive features of the Cottonhat Software Unipessoal LDA services or websites you visit.
                        </p>
                    </ul>

                    <h3>Data security & Integrity</h3>
                    <p>
                        Any and all personal information that we may collect will be collected, used and held in accordance with the provisions of General Data Protection Regulation (EU 2016/679) and your rights and Our obligations under that Act. <br />
                        The Website secures your Personal Data from unauthorised access, use or disclosure. The Website secures the Personal Data you provide on computer servers in a controlled, secure environment, protected from unauthorised access, use or disclosure. <br />
                        When personal information (such as a credit card number) is transmitted to other websites, it is protected through the use of encryption, such as the Secure Socket Layer (SSL) protocol. Where we have given you (or where you have chosen) a password which enables you to access
                        certain parts of our site, you are responsible for keeping this password confidential. <br />
                        <br />
                        We ask you not to share a password with anyone.
                    </p>

                    <h3>Your rights</h3>
                    <p>
                        You have the right to ask us not to process your Personal Data for marketing purposes. <br />
                        We will usually inform you (before collecting your data) if we intend to use your data for such purposes or if we intend to disclose your information to any third party for such purposes. <br />
                        You can exercise your right to prevent such processing by checking certain boxes on the forms we use to collect your data. <br />
                        You can also exercise the right at any time by contacting us at privacy@cottonhat.net.
                    </p>

                    <h3>Right to access, change or delete Personal Data</h3>
                    <p>
                        Right to access: the Act gives Data Subjects the right to access information held about them, to ensure that such Personal Data is accurate and relevant for the purposes for which the Website collected the data. <br />
                        You may review your Personal Data stored in the the Website databases and correct, erase or block any data that is incorrect, as permitted by applicable law. <br />
                        Your right of access can be exercised in accordance with the Act. <br />
                        You may edit your Personal Data by logging into your account or by contacting us by phone or email. <br />
                        <br />
                        In making modifications to your Personal Data, you must provide only truthful, complete and accurate information. <br />
                        Satisfying Requests for Access, Modifications, and Corrections: we will endeavor to respond in a timely manner to all reasonable written requests to view, modify, or inactivate Personal Data. <br />
                        <br />
                        Employees: Cottonhat Software Unipessoal LDA Employees may access and use Personal Data only if they are authorised to do so and only for the purpose for which they are authorised.
                    </p>

                    <h3>Changes to this Statement</h3>
                    <p>
                        Cottonhat Software Unipessoal LDA will occasionally update this Statement of Privacy to reflect company and customer feedback. <br />
                        Cottonhat Software Unipessoal LDA encourages you to periodically review this Statement to be informed of how Cottonhat Software Unipessoal LDA is protecting your information.
                    </p>

                    <h3>Contact information</h3>
                    <p>Cottonhat Software Unipessoal LDA welcomes your comments regarding this Statement of Privacy. If you believe that the Website has not adhered to this Statement of Privacy, please contact us at info@cottonhat.net.</p>
                </div>
            </div>
        </div>
    </Layout>
);

export default PrivacyPagePT;
